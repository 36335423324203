import React, { Suspense, useMemo, useRef, useState } from "react";
import { useFrame, extend } from "@react-three/fiber";
import { useGLTF, useTexture, Outlines } from "@react-three/drei";
import * as THREE from "three";
import { useSuperFan } from "../../context";
import { motion } from "framer-motion-3d";

export const Ball = React.forwardRef((props, ref) => {
  // This reference will give us direct access to the mesh
  const { canvasRef, type = 1, position = [0, 0, 0], rotation } = props;
  const { uploadFile } = useSuperFan();
  const [enabled, setEnabled] = useState(false);

  const textureRef = useRef();
  const { nodes, materials } = useGLTF(
    "/coach-holiday-bundle/coach-holiday-ornament.glb"
  );

  // use texture loader to load the image
  const texture_bird = useTexture("/coach-holiday-bundle/bird.webp");
  const texture_nutcracker = useTexture(
    "/coach-holiday-bundle/nutcracker.webp"
  );
  const texture_reindeer = useTexture("/coach-holiday-bundle/reindeer.webp");
  const texture_snowman = useTexture("/coach-holiday-bundle/snowman.webp");

  const colorTexture = useTexture("/2d/B_glow.webp");

  const handerLer = useMemo(() => {
    switch (type) {
      case 1:
        return {
          texture: texture_snowman,
          color: "#1464b0",
        };

      case 2:
        return {
          texture: texture_reindeer,
          color: "#327e4f",
        };
      case 3:
        return {
          texture: texture_nutcracker,
          color: "#fe243a",
        };
      case 4:
        return {
          texture: texture_bird,
          color: "#dc8f13",
        };

      default:
        return {
          texture: texture_snowman,
          color: "#177bd9",
        };
    }
  }, []);

  useFrame((delta) => {
    textureRef.current.needsUpdate = true;
  });

  return (
    <Suspense fallback={null}>
      <motion.group
        dispose={null}
        ref={ref}
        rotation={[0, Math.PI, 0]}
        scale={[-0.52, 0.52, 0.52]}
        {...props}
        animate={{
          x: position[0],
          y: position[1],
          z: position[2],
          rotateX: rotation[0],
          rotateY: rotation[1],
          rotateZ: rotation[2],

          transition: {
            duration: 1,
            ease: "backInOut",
          },
        }}
      >
        <group rotation={[Math.PI / 2, 0, 0]}>
          {/* <BloomBall /> */}

          <mesh
            geometry={nodes.top.geometry}
            material={materials.top}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={1.675}
          ></mesh>

          <mesh geometry={nodes.bottom.geometry}>
            <meshPhysicalMaterial
              color={handerLer.color}
              roughness={0.25}
              metalness={0.7}
              ior={1.25}
              renderOrder={10}
              transparent={false}
              blending={THREE.NoBlending}
            />
          </mesh>

          <mesh
            geometry={nodes.sticker_layer.geometry}
            rotation={[Math.PI / 2, 0, 0]}
            scale={1.675}
          >
            <meshBasicMaterial
              transparent
              map={handerLer.texture}
            ></meshBasicMaterial>
          </mesh>

          <mesh
            geometry={nodes.text_layer.geometry}
            material={materials.text}
            rotation={[-Math.PI / 2, 0, Math.PI]}
            scale={[1.676, 1.676, 1.676]}
            onClick={(e) => {
              let inputMessage = document.getElementById("inputMessage");
              let inputForm = document.getElementById("inputMessage2");

              if (e.uv.y > 0.4) {
                inputMessage.focus();
              } else {
                inputForm.focus();
              }
              // canvasRef.current.scene.scenes[0].snapShot(uploadFile);
            }}
          >
            <meshStandardMaterial transparent intensity={0}>
              <canvasTexture
                ref={textureRef}
                attach="map"
                image={canvasRef.current}
              />
            </meshStandardMaterial>
          </mesh>
        </group>
      </motion.group>
    </Suspense>
  );
});

const fragmentShader = `
  varying vec3 vNormal; 
  void main() { 
    float intensity = pow( 0.7 - dot( vNormal, vec3( 0.0, 0.0, 0.5 ) ), 4.0 );
    vec3 color = vec3(0.498, 0.937, 0.921);
 
    gl_FragColor = vec4( color, 1.0 ) * intensity;
  }
`;

const vertexShader = `
  varying vec3 vNormal; 
  void main() { 
    vNormal = normalize( normalMatrix * normal );
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 ); 
  }
`;

const BloomBall = React.forwardRef((props, ref) => {
  const data = useMemo(
    () => ({
      uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector2() },
      },
      vertexShader,
      fragmentShader,
    }),
    []
  );

  return (
    <>
      <mesh renderOrder={0} position={[0, 0, 0]} scale={[11, 11, 11]}>
        <sphereGeometry args={[0.062, 32, 16]} />
        <shaderMaterial
          {...data}
          side={THREE.BackSide}
          transparent={true}
          blending={THREE.AdditiveBlending}
        />
      </mesh>
    </>
  );
});
