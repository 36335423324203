import Phaser from "phaser";

export class Bootstrap extends Phaser.Scene {
  constructor() {
    super("bootstrap");
  }

  init() {}

  preload() {
    this.load.image("logo", "/2d/logolanding.webp");
    this.load.image("light1", "/2d/light1.webp");
    this.load.image("light2", "/2d/light2.webp");
    this.load.image("light3", "/2d/light3.webp");
    this.load.image("b_glow", "/2d/B_glow.webp");
    this.load.image("swipe1", "/2d/swipe1.webp");
    this.load.image("swipe2", "/2d/swipe2.webp");
    this.load.image("swipe3", "/2d/swipe3.webp");
    this.load.image("firework1", "/2d/firework1.webp");

    // load sprite sheet
    this.load.spritesheet("tap_icon", "/2d/tap_icon.webp", {
      frameWidth: 300,
      frameHeight: 700,
    });
  }

  create() {
    // create animation
    // play sprite sheetx
    this.anims.create({
      key: "tap_anim",
      frames: this.anims.generateFrameNumbers("tap_icon", {
        start: 0,
        end: 29,
      }),
      frameRate: 10,
      repeat: -1,
    });

    this.anims.create({
      key: "light_anim1",
      frames: [
        {
          key: "light1",
        },
        {
          key: "light2",
        },
        {
          key: "light3",
        },
      ],
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "light_anim2",
      frames: [
        {
          key: "light3",
        },
        {
          key: "light2",
        },
        {
          key: "light1",
        },
      ],
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "swipe_anim",
      frames: [
        {
          key: "swipe1",
        },
        {
          key: "swipe2",
        },
        {
          key: "swipe3",
        },
      ],
      frameRate: 2,
      repeat: -1,
    });

    this.game.scene.start("game");
  }

  update() {}
}

function ArrayFrame(start, end, reverse) {
  if (reverse) {
    let arr = [];
    for (let i = start; i >= end; i--) {
      arr.push({ key: "light" + i.toString().padStart(1, "0") });
    }
    return arr;
  } else {
    let arr = [];
    for (let i = start; i <= end; i++) {
      arr.push({ key: "light" + i.toString().padStart(1, "0") });
    }
    return arr;
  }
}
