import * as React from "react";
import { motion } from "framer-motion";
const SvgComponent1 = (props) => {
  const { trigger1, setIsPlaying, isPlaying } = props;
  return (
    <svg width="100%" height="100%" viewBox="0 0 160 160">
      <circle
        r="70"
        cx="80"
        cy="80"
        fill="#C22033"
        stroke="#e0e0e0"
        strokeWidth="8px"
      ></circle>
      <circle
        r="70"
        cx="80"
        cy="80"
        fill="transparent"
        stroke="#e0e0e0"
        strokeWidth="8px"
      ></circle>
      <motion.circle
        r="70"
        cx="80"
        cy="80"
        fill="transparent"
        stroke="#60e6a8"
        strokeWidth="8px"
        strokeDasharray="439.6px"
        strokeDashoffset="109.9px"
        onAnimationComplete={() => {
          setIsPlaying(false);
        }}
        animate={{
          strokeDashoffset: trigger1 ? [439.6, 0] : [439.7, 0],
          transition: {
            duration: 6,
            ease: "easeInOut",
          },
        }}
      ></motion.circle>

      {!isPlaying ? (
        <image href="/2d/redrocket.png" height="90" width="90" x={35} y={35} />
      ) : (
        <image href="/2d/R_firework.png" height="90" width="90" x={35} y={35} />
      )}
    </svg>
  );
};

export default SvgComponent1;
