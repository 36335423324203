import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  Suspense,
} from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  Environment,
  Float,
  OrbitControls,
  useTexture,
} from "@react-three/drei";
import { Ball } from "./components/ball";
import { EffectComposer, N8AO } from "@react-three/postprocessing";
import { useSuperFan } from "../context";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import gsap from "gsap";
import { animate } from "framer-motion";
import axios from "axios";

export default function THREESCENE({
  canvasRef,
  setCurrentPage,
  currentPage,
  setInfo,
  info,
  cameraFocus,
}) {
  return (
    <Canvas
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        zIndex: "1",
      }}
      shadows
      gl={{ alpha: true, stencil: false, depth: false, antialias: false }}
      camera={{ position: [0, 0, 5], fov: 32.5, near: 0.1, far: 100 }}
      id="THREECANVAS"
      onCreated={(state) => {
        state.gl.toneMappingExposure = 1.5;
      }}
    >
      <BallControl
        canvasRef={canvasRef}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setInfo={setInfo}
        info={info}
      />

      <EffectComposer disableNormalPass multisampling={0}>
        <N8AO
          halfRes
          color="red"
          aoRadius={2}
          intensity={0.8}
          aoSamples={6}
          denoiseSamples={4}
        />
      </EffectComposer>
      {/*  */}
      {/* embient light */}
      <ambientLight intensity={0.3} />
      <Environment files="/royal_esplanade_1k.hdr" background={false} />
    </Canvas>
  );
}

const BallControl = ({
  canvasRef,
  setCurrentPage,
  currentPage,
  setInfo,
  info,
}) => {
  const { viewport, camera } = useThree();
  const [sellected, setSellected] = useState(null);
  const { submit_temp } = useSuperFan();
  const ball1Ref = useRef();
  const ball2Ref = useRef();
  const ball3Ref = useRef();
  const ball4Ref = useRef();
  const [triggerUp, setTriggerUp] = useState(false);
  const uploaded = useRef(false);

  const ori = {
    1: {
      position: [viewport.width * 0.28, viewport.height * 0.03, 0],
      rotation: [0, Math.PI, Math.PI * 0.05],
      randomSpeed: 2 - Math.random() * 1,
      p2: {
        position: [viewport.width * 0.3, viewport.height * 0.3, -1],
        rotation: [0, Math.PI, Math.PI * 0.02],
      },
      p3: {
        position: [viewport.width, viewport.height * 0.6, -1],
        rotation: [0, Math.PI, Math.PI * 0.02],
      },
    },
    2: {
      position: [-viewport.width * 0.2, -viewport.height * 0.17, 0],
      rotation: [0, Math.PI, Math.PI * 0.1 * -1],
      randomSpeed: 2 - Math.random() * 1,

      p2: {
        position: [-viewport.width * 0.32, -viewport.height * 0.22, -1],
        rotation: [0, Math.PI, Math.PI * 0.02 * -1],
      },

      p3: {
        position: [-viewport.width, -viewport.height * 0.7, -1],
        rotation: [0, Math.PI, Math.PI * 0.02 * -1],
      },
    },
    3: {
      position: [viewport.width * 0.25, -viewport.height * 0.2, 0],
      rotation: [0, Math.PI, Math.PI * 0.1],
      randomSpeed: 2 - Math.random() * 1,

      p2: {
        position: [viewport.width * 0.36, -viewport.height * 0.33, -1],
        rotation: [0, Math.PI, Math.PI * 0.02],
      },

      p3: {
        position: [viewport.width, -viewport.height * 0.7, -1],
        rotation: [0, Math.PI, Math.PI * 0.02],
      },
    },
    4: {
      position: [-viewport.width * 0.23, viewport.height * 0.08, 0.4],
      rotation: [0, Math.PI, Math.PI * 0.05 * -1],
      randomSpeed: 2 - Math.random() * 1,
      p2: {
        position: [-viewport.width * 0.44, viewport.height * 0.22, -1],
        rotation: [0, Math.PI, Math.PI * 0.02 * -1],
      },

      p3: {
        position: [-viewport.width, viewport.height * 0.6, -1],
        rotation: [0, Math.PI, Math.PI * 0.02 * -1],
      },
    },
  };

  const ball1Anis = useMemo(
    (index = 1) => {
      if (sellected === index) {
        if (triggerUp) {
          return {
            position: [0, viewport.height * 0.4, 2.5],
            rotation: [0, Math.PI, 0],
            easeType: "linear",
          };
        }

        if (currentPage >= 4) {
          return {
            position: [0, 0, 2.5],
            rotation: [0, 0, 0],
          };
        }
        return {
          position: [0, 0, 2],
          rotation: [0, Math.PI, 0],
        };
      }

      if (currentPage === 1 || currentPage === 2 || currentPage === 3) {
        return {
          position: ori[index].position,
          rotation: ori[index].rotation,
        };
      } else if (currentPage === 4) {
        return {
          position: ori[index].p2.position,
          rotation: ori[index].p2.rotation,
        };
      } else if (currentPage === 5 || currentPage === 6) {
        return {
          position: ori[index].p3.position,
          rotation: ori[index].p3.rotation,
        };
      }

      return {
        position: ori[index].position,
        rotation: ori[index].rotation,
      };
    },
    [sellected, currentPage, triggerUp]
  );

  const ball2Anis = useMemo(
    (index = 2) => {
      if (sellected === index) {
        if (triggerUp) {
          return {
            position: [0, viewport.height * 0.4, 2.5],
            rotation: [0, Math.PI, 0],
            easeType: "linear",
          };
        }

        if (currentPage >= 4) {
          return {
            position: [0, 0, 2.5],
            rotation: [0, 0, 0],
          };
        }
        return {
          position: [0, 0, 2],
          rotation: [0, Math.PI, 0],
        };
      }

      if (currentPage === 1 || currentPage === 2 || currentPage === 3) {
        return {
          position: ori[index].position,
          rotation: ori[index].rotation,
        };
      } else if (currentPage === 4) {
        return {
          position: ori[index].p2.position,
          rotation: ori[index].p2.rotation,
        };
      } else if (currentPage === 5 || currentPage === 6) {
        return {
          position: ori[index].p3.position,
          rotation: ori[index].p3.rotation,
        };
      }

      return {
        position: ori[index].position,
        rotation: ori[index].rotation,
      };
    },
    [sellected, currentPage, triggerUp]
  );

  const ball3Anis = useMemo(
    (index = 3) => {
      if (sellected === index) {
        if (triggerUp) {
          return {
            position: [0, viewport.height * 0.4, 2.5],
            rotation: [0, Math.PI, 0],
            easeType: "linear",
          };
        }

        if (currentPage >= 4) {
          return {
            position: [0, 0, 2.5],
            rotation: [0, 0, 0],
          };
        }
        return {
          position: [0, 0, 2],
          rotation: [0, Math.PI, 0],
        };
      }

      if (currentPage === 1 || currentPage === 2 || currentPage === 3) {
        return {
          position: ori[index].position,
          rotation: ori[index].rotation,
        };
      } else if (currentPage === 4) {
        return {
          position: ori[index].p2.position,
          rotation: ori[index].p2.rotation,
        };
      } else if (currentPage === 5 || currentPage === 6) {
        return {
          position: ori[index].p3.position,
          rotation: ori[index].p3.rotation,
        };
      }

      return {
        position: ori[index].position,
        rotation: ori[index].rotation,
      };
    },
    [sellected, currentPage, triggerUp]
  );

  const ball4Anis = useMemo(
    (index = 4) => {
      if (sellected === index) {
        if (triggerUp) {
          return {
            position: [0, viewport.height * 0.4, 2.5],
            rotation: [0, Math.PI, 0],
            easeType: "linear",
          };
        }

        if (currentPage >= 4) {
          return {
            position: [0, 0, 2.5],
            rotation: [0, 0, 0],
          };
        }
        return {
          position: [0, 0, 2],
          rotation: [0, Math.PI, 0],
        };
      }

      if (currentPage === 1 || currentPage === 2 || currentPage === 3) {
        return {
          position: ori[index].position,
          rotation: ori[index].rotation,
        };
      } else if (currentPage === 4) {
        return {
          position: ori[index].p2.position,
          rotation: ori[index].p2.rotation,
        };
      } else if (currentPage === 5 || currentPage === 6) {
        return {
          position: ori[index].p3.position,
          rotation: ori[index].p3.rotation,
        };
      }

      return {
        position: ori[index].position,
        rotation: ori[index].rotation,
      };
    },
    [sellected, currentPage, triggerUp]
  );

  const animate = (ballRef, index) => {
    if (currentPage === 1) setCurrentPage(2);

    let theme = document.getElementsByTagName("meta")["theme-color"];

    switch (index) {
      case 1:
        theme.content = "#198dfa";
        break;
      case 2:
        theme.content = "#1eae35";
        break;
      case 3:
        theme.content = "#e81111";
        break;
      case 4:
        theme.content = "#fdba0f";

        break;
      default:
        theme.content = "#198dfa";
        break;
    }
    setSellected(index);
    setInfo({
      ...info,
      color: index,
    });
  };

  const animateCamera = (position) => {
    gsap.to(camera.position, {
      x: position[0],
      y: position[1],
      z: position[2],
      duration: 1,
    });
  };

  useEffect(() => {
    window.animateCamera = (pos) => {
      animateCamera(pos);
    };

    window.setTriggerUp = (bool = true) => {
      setTriggerUp(bool);
    };
  }, []);

  useMemo(() => {
    if (triggerUp) {
      const dataModel = {
        colour: info.color - 1,
        dataUrl: info.url,
        sender: info.sender,
        type: 1,
      };

      fetch(
        "https://coach-holiday-interective-middleware.onrender.com/ornament",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataModel),
        }
      )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });

      setCurrentPage(6);
    }
  }, [triggerUp]);

  useFrame((state, delta) => {
    let ball = [ball1Ref, ball2Ref, ball3Ref, ball4Ref];
    ball.forEach((b, index) => {
      if (index !== sellected - 1) {
        b.current.position.y +=
          Math.sin(state.clock.elapsedTime * 1.2 * ori[index + 1].randomSpeed) *
          0.0005;
      }

      if (index === sellected - 1 && currentPage === 5) {
        b.current.rotation.z +=
          Math.cos(state.clock.elapsedTime * 1.2 * ori[index + 1].randomSpeed) *
          0.002;

        b.current.position.y +=
          Math.sin(state.clock.elapsedTime * 1.2 * ori[index + 1].randomSpeed) *
          0.0005;
      }
    });
  });

  return (
    <>
      <Ball
        canvasRef={canvasRef}
        type={1}
        ref={ball1Ref}
        position={ball1Anis.position}
        rotation={ball1Anis.rotation}
        onClick={(e) => {
          e.stopPropagation();
          animate(ball1Ref.current, 1);
        }}
      />

      <Ball
        canvasRef={canvasRef}
        type={2}
        ref={ball2Ref}
        position={ball2Anis.position}
        rotation={ball2Anis.rotation}
        onClick={(e) => {
          e.stopPropagation();
          animate(ball2Ref.current, 2);
        }}
      />
      <Ball
        canvasRef={canvasRef}
        type={3}
        ref={ball3Ref}
        position={ball3Anis.position}
        rotation={ball3Anis.rotation}
        onClick={(e) => {
          e.stopPropagation();
          animate(ball3Ref.current, 3);
        }}
      />

      <Ball
        canvasRef={canvasRef}
        type={4}
        ref={ball4Ref}
        position={ball4Anis.position}
        rotation={ball4Anis.rotation}
        onClick={(e) => {
          e.stopPropagation();
          animate(ball4Ref.current, 4);
        }}
      />
    </>
  );
};
