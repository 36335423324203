import Phaser from "phaser";
import * as THREE from "three";
import { Flake } from "./flake";
export class Game extends Phaser.Scene {
  constructor() {
    super("game");
  }

  init() {
    // load font
  }

  preload() {}

  create() {
    this.playFlake = false;
    this.logo = this.add.sprite(
      this.game.config.width * 0.5,
      this.game.config.height * 0.1,
      "logo"
    );

    this.tap = this.add.sprite(
      this.game.config.width / 2 + this.game.config.height * 0.1,
      this.game.config.height / 2 + this.game.config.height * 0.08,
      "tap_icon"
    );
    this.tap.anims.play("tap_anim");
    this.tap.alpha = 0;
    this.tap.setDisplaySize(
      (this.game.config.height * 0.2 * 3) / 7,
      this.game.config.height * 0.2
    );

    this.logo.setDisplaySize(
      this.game.config.height * 0.11 * 1.77,
      this.game.config.height * 0.11
    );

    this.lights = this.add.sprite(0, 0, "light1");
    this.lights
      .setDisplaySize(
        this.game.config.height * 0.1 * 1.65,
        this.game.config.height * 0.1
      )
      .setOrigin(1, 1);

    this.lights.rotation = Math.PI * 1;

    this.lights.anims.play("light_anim1");

    this.lights2 = this.add.sprite(
      this.game.config.width,
      this.game.config.height,
      "light1"
    );

    this.lights2.anims.play("light_anim2");

    this.lights2
      .setDisplaySize(
        this.game.config.height * 0.1 * 1.65,
        this.game.config.height * 0.1
      )
      .setOrigin(1, 1);

    this.swipe = this.add
      .sprite(
        this.game.config.width * 0.5,
        this.game.config.height - this.game.config.height * 0.16,
        "swipe1"
      )
      .setDisplaySize(
        this.game.config.height * 0.2 * 0.686,
        this.game.config.height * 0.2
      );

    this.swipe.alpha = 0;

    this.swipe.anims.play("swipe_anim");

    this.startPoint = {
      x: null,
      y: null,
    };
    this.input.on("pointerdown", (e) => {
      this.startPoint.x = e.x;
      this.startPoint.y = e.y;
    });

    this.input.on("pointerup", (e) => {
      let direction = new THREE.Vector2(e.x, e.y)
        .sub(new THREE.Vector2(this.startPoint.x, this.startPoint.y))
        .normalize();

      if (direction.y < 0) {
        this.endGamehander();
        window.setTriggerUp(true);
        this.swipe.alpha = 0;
      } else {
        console.log("down");
      }
    });
  }

  endGamehander() {
    this.flakes = [];
    let size = Math.pow(
      this.game.config.width * this.game.config.height * 0.02,
      0.5
    );
    for (var i = 0; i < size; i++) {
      var f = new Flake(this);
      this.flakes.push(f);
    }

    this.playFlake = true;
  }
  update(e) {
    if (this.playFlake) {
      this.flakes.forEach((f) => {
        try {
          f.move();
        } catch (err) {}
      });
    }
  }
}
