import { useMemo, useRef, useState, useEffect } from "react";
import { Button, Input, Terms } from "./components";
import { useThree } from "@react-three/fiber";
import { useSuperFan } from "../context/index";
import SvgComponent from "./components/svg.js";
import SvgComponent1 from "./components/svg1.js";
import SvgComponent2 from "./components/svg2.js";
import { gsap } from "gsap";
import BadWords from "./badwords";
export const Input_sphere = ({
  canvasRef,
  index,
  currentPage,
  setOnFocus,
  setInfo,
  info,
  onFocus,
  phaserRef,
}) => {
  const inputRef = useRef(null);
  const { trackBtn } = useSuperFan();

  function wrapText(context, text, x, y) {
    context.clearRect(0, (512 / 2) * 0.7, 1024, 150);
    var words = text;
    var line = "";
    let arr = [];
    let lineheight = 40;
    context.font = "38px HelveticaLTPro-Bold";
    // letter spacing
    context.fillStyle = "#ffffff";
    // height of the text
    context.textAlign = "center";

    for (let i = 0; i < text.length; i++) {
      context.fillText(text[i], x + 10, y + i * lineheight);
    }
  }

  function wrapText2(context, text, x, y) {
    context.clearRect(0, (512 / 2) * 0.65 + 150, 1024, 212);
    var words = text;
    var line = "";
    let arr = [];
    let lineheight = 30;
    context.font = "28px HelveticaLTPro-Bold";
    // letter spacing
    context.fillStyle = "#ffffff";
    // height of the text
    context.textAlign = "center";

    for (let i = 0; i < text.length; i++) {
      context.fillText(text[i], x + 10, y + i * lineheight);
    }
  }

  return (
    <>
      <input
        id="inputMessage"
        onFocus={(e) => {
          e.preventDefault();
          window.animateCamera([0, -0.1, 4.3]);
          phaserRef.current.scene.game.scene.scenes[1].tap.alpha = 0;
          setOnFocus(true);

          let ctx = canvasRef.current.getContext("2d");
          let x = canvasRef.current.width / 2;
          let y = canvasRef.current.height * 0.42;
          let maxWidth = 200;
          let lineHeight = 38;

          let text = info.url ?? "";
          text += "|";
          text = text.split("\n");
          wrapText(ctx, text, x, y, maxWidth, lineHeight);
        }}
        onClick={() => {
          trackBtn("sphere");
        }}
        // leave the input
        onBlur={(e) => {
          e.preventDefault();
          window.animateCamera([0, 0, 5]);
          setOnFocus(false);

          let ctx = canvasRef.current.getContext("2d");
          let x = canvasRef.current.width / 2;
          let y = canvasRef.current.height * 0.42;
          let maxWidth = 200;
          let lineHeight = 38;

          let text = info.url ?? "";
          text = text.split("\n");
          wrapText(ctx, text, x, y, maxWidth, lineHeight);
        }}
        style={{
          pointerEvents: index.includes(currentPage) ? "none" : "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50vw",
          height: "50vw",
          maxHeight: "350px",
          maxWidth: "350px",
          borderRadius: "50%",
          opacity: 0,
          backgroundColor: "red",
        }}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.target.value += "$";

            let ctx = canvasRef.current.getContext("2d");
            let x = canvasRef.current.width / 2;
            let y = canvasRef.current.height * 0.42;
            let maxWidth = 200;
            let lineHeight = 38;

            let text = info.url ?? "";
            text = text.split("\n");
            if (text.length < 3) {
              text.push("|");
              wrapText(ctx, text, x, y, maxWidth, lineHeight);
            }
          }
          e.target.selectionStart = e.target.selectionEnd =
            e.target.value.length;
        }}
        onChange={(e) => {
          // alway sellect to the end of the text
          let ctx = canvasRef.current.getContext("2d");
          let text = e.target.value.toUpperCase();
          let x = canvasRef.current.width / 2;
          let y = canvasRef.current.height * 0.42;
          let maxWidth = 200;
          let lineHeight = 38;

          let final = [];
          let result = text.split("$");
          for (let j = 0; j < result.length; j++) {
            if (result[j].includes("$")) {
              let preArr = result[j].split("$");

              for (let k = 0; k < preArr.length; k++) {
                final.push(preArr[k]);
              }
            } else {
              final.push(result[j]);
            }
          }

          // if value include chinese or japanese
          let size = text.match(/[\u3400-\u9FBF]/) ? 9 : 16;
          let ffnal = [];

          for (let z = 0; z < final.length; z++) {
            let tempArr = [];
            for (let j = 0; j < final[z].length; j += size) {
              tempArr.push(final[z].slice(j, j + size));
            }
            for (let k = 0; k < tempArr.length; k++) {
              ffnal.push(tempArr[k]);
            }
          }
          let _text = "";

          for (let i = 0; i < ffnal.length; i++) {
            _text += ffnal[i] + "\n";
          }

          _text = _text.slice(0, _text.length - 1);
          setInfo({
            ...info,
            url: _text,
          });

          ffnal[ffnal.length - 1] = ffnal[ffnal.length - 1] + "|";

          if (ffnal.length >= 4) {
            setInfo({
              ...info,
              url: _text.slice(0, _text.length - 1),
            });
            e.target.value = text.slice(0, text.length - 1);
          } else {
            wrapText(ctx, ffnal, x, y, maxWidth, lineHeight);
          }
        }}
      />

      <input
        id="inputMessage2"
        onFocus={(e) => {
          e.preventDefault();
          window.animateCamera([0, -0.1, 4.3]);
          phaserRef.current.scene.game.scene.scenes[1].tap.alpha = 0;

          setOnFocus(true);

          let ctx = canvasRef.current.getContext("2d");
          let x = canvasRef.current.width / 2;
          let y = canvasRef.current.height * 0.68;
          let maxWidth = 200;
          let lineHeight = 30;

          let text = info.sender ?? "";
          text += "|";
          text = text.split("\n");
          wrapText2(ctx, text, x, y, maxWidth, lineHeight);
        }}
        // leave the input
        onBlur={(e) => {
          e.preventDefault();
          window.animateCamera([0, 0, 5]);
          setOnFocus(false);

          let ctx = canvasRef.current.getContext("2d");
          let x = canvasRef.current.width / 2;
          let y = canvasRef.current.height * 0.68;
          let maxWidth = 200;
          let lineHeight = 30;

          let text = info.sender ?? "";
          text = text.split("\n");
          wrapText2(ctx, text, x, y, maxWidth, lineHeight);
        }}
        style={{
          pointerEvents: index.includes(currentPage) ? "none" : "none",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50vw",
          height: "50vw",
          maxHeight: "350px",
          maxWidth: "350px",
          borderRadius: "50%",
          opacity: 0,
        }}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.target.value += "$";

            let ctx = canvasRef.current.getContext("2d");
            let x = canvasRef.current.width / 2;
            let y = canvasRef.current.height * 0.68;
            let maxWidth = 200;
            let lineHeight = 30;

            let text = info.sender ?? "";
            text = text.split("\n");
            if (text.length < 2) {
              text.push("|");
              wrapText2(ctx, text, x, y, maxWidth, lineHeight);
            }
          }
          e.target.selectionStart = e.target.selectionEnd =
            e.target.value.length;
        }}
        onChange={(e) => {
          // alway sellect to the end of the text
          let ctx = canvasRef.current.getContext("2d");
          let text = e.target.value.toUpperCase();
          let x = canvasRef.current.width / 2;
          let y = canvasRef.current.height * 0.68;
          let maxWidth = 200;
          let lineHeight = 30;

          let final = [];
          let result = text.split("$");
          for (let j = 0; j < result.length; j++) {
            if (result[j].includes("$")) {
              let preArr = result[j].split("$");

              for (let k = 0; k < preArr.length; k++) {
                final.push(preArr[k]);
              }
            } else {
              final.push(result[j]);
            }
          }

          let size = 15;
          let ffnal = [];

          for (let z = 0; z < final.length; z++) {
            let tempArr = [];
            for (let j = 0; j < final[z].length; j += size) {
              tempArr.push(final[z].slice(j, j + size));
            }
            for (let k = 0; k < tempArr.length; k++) {
              ffnal.push(tempArr[k]);
            }
          }
          let _text = "";

          for (let i = 0; i < ffnal.length; i++) {
            _text += ffnal[i] + "\n";
          }

          _text = _text.slice(0, _text.length - 1);

          setInfo({
            ...info,
            sender: _text,
          });

          ffnal[ffnal.length - 1] = ffnal[ffnal.length - 1] + "|";
          if (ffnal.length >= 3) {
            setInfo({
              ...info,
              sender: _text.slice(0, _text.length - 1),
            });
            e.target.value = text.slice(0, text.length - 1);
          } else {
            wrapText2(ctx, ffnal, x, y, maxWidth, lineHeight);
          }
        }}
      />
    </>
  );
};

export const Page12 = ({
  canvasRef,
  currentPage,
  setCurrentPage,
  info,
  index = [],
}) => {
  const { trackBtn } = useSuperFan();
  return (
    <div
      style={{
        opacity: index.includes(currentPage) ? 1 : 0,
        pointerEvents: index.includes(currentPage) ? "none" : "none",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100svh",
        transition: "1s",
      }}
    >
      <div
        className="block"
        style={{
          height: "17svh",
        }}
      />
      <h3>
        WELCOME TO COACH HOLIDAY <br />
        IMMERSIVE EXPERIENCE
        <div
          className="block"
          style={{
            height: "2svh",
          }}
        />
        <span
          style={{
            fontFamily: "HelveticaLTPro-Light",
            fontSize: "2svh",
          }}
        >
          PICK AN ORNAMENT{" "}
        </span>
      </h3>
      <div
        className="block"
        style={{
          height: "55svh",
        }}
      />

      <div
        style={{
          pointerEvents: currentPage === 2 ? "auto" : "none",
          opacity: currentPage === 2 ? 1 : 0,
          transition: "1s",
        }}
      >
        <Button
          size="50%"
          param="NEXT"
          backgroundColor="#C22033"
          id="nextFirst"
          onClick={() => {
            // window.spin(info.color);

            if (localStorage.getItem("replay") === "true") {
              setCurrentPage(4);
            } else {
              setCurrentPage(3);
            }
          }}
        />
      </div>
    </div>
  );
};

export const Page3 = ({
  canvasRef,
  currentPage,
  setCurrentPage,
  index = [],
  setInfo,
  info,
  phaserRef,
}) => {
  const [onFocus, setOnFocus] = useState({
    input1: false,
    input2: false,
  });
  const { submit, trackBtn } = useSuperFan();

  return (
    <div
      style={{
        opacity: index.includes(currentPage) ? 1 : 0,
        pointerEvents: index.includes(currentPage) ? "auto" : "none",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100svh",
        transition: "1s",
      }}
    >
      <div
        style={{
          backgroundColor: "#C22033",
          height: "80svh",
          width: "90%",
          margin: "0 auto",
          borderRadius: "1rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="block"
          style={{
            height: "4svh",
          }}
        />
        <div
          className="imgContainer"
          style={{
            height: "10svh",
          }}
        >
          <img src="/2d/logolanding.webp" alt="logoloading" />
        </div>

        <div
          className="block"
          style={{
            height: "3svh",
          }}
        />
        <h3>TELL US ABOUT YOURSELF</h3>
        <div
          className="block"
          style={{
            height: "2svh",
          }}
        />
        <div
          style={{
            padding: "0 3rem",
          }}
        >
          <Input
            placeholder="NAME"
            onChange={(e) => {
              setInfo({
                ...info,
                name: e.target.value,
              });
            }}
            onBlur={() => {
              setOnFocus({
                ...onFocus,
                input1: true,
              });
            }}
            onFocus={() => {
              setOnFocus({
                ...onFocus,
                input1: false,
              });
            }}
          />
          <Input
            placeholder="EMAIL"
            onChange={(e) => {
              setInfo({
                ...info,
                email: e.target.value,
              });
            }}
            onBlur={() => {
              setOnFocus({
                ...onFocus,
                input2: true,
              });
            }}
            onFocus={() => {
              setOnFocus({
                ...onFocus,
                input2: false,
              });
            }}
          />

          <Terms terms={info.terms} setInfo={setInfo} />
        </div>
        <div
          className="block"
          style={{
            height: "2svh",
          }}
        />
        <Button
          size="50%"
          param="NEXT"
          onClick={async () => {
            if (!info.terms) {
              alert("Please agree to the terms and conditions");
              return;
            } else if (info.name === "" || info.email === "") {
              alert("Please fill in your name and email");
              return;
            }

            phaserRef.current.scene.game.scene.scenes[1].tap.alpha = 1;
            localStorage.setItem("replay", true);
            trackBtn("nextpage2");
            console.log(info);
            submit(info);
            setCurrentPage(4);
          }}
        />

        <Button
          size="50%"
          param="BACK"
          onClick={async () => {
            setCurrentPage(2);
            trackBtn("backpage2");
          }}
        />
      </div>
    </div>
  );
};

export const Page4 = ({
  canvasRef,
  currentPage,
  setCurrentPage,
  index = [],
  setInfo,
  info,
  phaserRef,
}) => {
  const [onFocus, setOnFocus] = useState(false);
  const { uploadFile, trackBtn, submit } = useSuperFan();
  return (
    <div
      style={{
        opacity: index.includes(currentPage) ? 1 : 0,
        pointerEvents: index.includes(currentPage) ? "none" : "none",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100svh",
        transition: "1s",
      }}
    >
      <Input_sphere
        setOnFocus={setOnFocus}
        onFocus={onFocus}
        canvasRef={canvasRef}
        currentPage={currentPage}
        index={index}
        setInfo={setInfo}
        info={info}
        phaserRef={phaserRef}
      />

      <div
        className="block"
        style={{
          height: "17svh",
        }}
      />
      <h3
        style={{
          opacity: onFocus ? 0 : 1,
          transition: "0.5s",
        }}
      >
        ENTER YOUR MESSAGE
      </h3>
      <div
        className="block"
        style={{
          height: "61.5svh",
        }}
      />

      <div
        style={{
          pointerEvents: currentPage === 4 && !onFocus ? "auto" : "none",
          opacity: !onFocus ? 1 : 0,
        }}
      >
        <Button
          size="50%"
          param="NEXT"
          id={"finalNext"}
          backgroundColor="#C22033"
          onClick={async () => {
            let input = document.getElementById("inputMessage");
            let input2 = document.getElementById("inputMessage2");
            let found = false;
            input.value.split(" ").forEach((word) => {
              if (BadWords.includes(word.toLowerCase())) {
                found = true;
              }
            });

            input2.value.split(" ").forEach((word) => {
              if (BadWords.includes(word.toLowerCase())) {
                found = true;
              }
            });

            if (found) {
              alert("Please remove bad words");
              return;
            }
            phaserRef.current.scene.game.scene.scenes[1].tap.alpha = 0;

            const base64Data = canvasRef.current.toDataURL();
            let res = await fetch(base64Data);
            let blob = await res.blob();
            let file = new File([blob], "my-image.png", {
              type: "image/png",
              lastModified: Date.now(),
            });

            let url = await uploadFile(file);
            trackBtn("nextpage4");

            setInfo({
              ...info,
              url: url,
            });
            submit({
              ...info,
            });
            setCurrentPage(5);
          }}
        />
      </div>
    </div>
  );
};

export const Page6 = ({
  canvasRef,
  currentPage,
  setCurrentPage,
  index = [],
  setInfo,
  info,
}) => {
  const { uploadFile, submit, trackBtn } = useSuperFan();
  const [trigger1, setTrigger1] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <div
      style={{
        opacity: index.includes(currentPage) ? 1 : 0,
        pointerEvents: index.includes(currentPage) ? "auto" : "none",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100svh",
        transition: "1s",
      }}
    >
      <div
        className="block"
        style={{
          height: "20svh",
        }}
      />
      <h3>Thank you for participating</h3>

      <p>
        Remember to tag us when you <br />
        share your Holiday Ornament!
        <br /> @Coach #CoachHoliday
      </p>

      <div
        className="block"
        style={{
          height: "5.5svh",
        }}
      />

      <div>
        <h3>TRIGGER FIREWORKS</h3>

        <div
          style={{
            display: "flex",
            margin: "5svh auto",
            width: "fit-content",
          }}
        >
          <div
            className="imgContainer firework1"
            style={{
              height: "12svh",
              width: "12svh",
              margin: "0 0.33rem",
            }}
            onClick={() => {
              if (!isPlaying) {
                setTrigger1(!trigger1);
                fetch(
                  "https://coach-holiday-interective-middleware.onrender.com/firework",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },

                    body: JSON.stringify({
                      colour: 0,
                    }),
                  }
                )
                  .then((res) => res.text())
                  .then((data) => {})
                  .catch((err) => {
                    console.error(err);
                  });

                setIsPlaying(true);
              }
            }}
          >
            <SvgComponent
              trigger1={trigger1}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
            />
          </div>

          <div
            className="imgContainer firework2"
            style={{
              height: "12svh",
              width: "12svh",
              margin: "0 0.33rem",
            }}
            onClick={() => {
              if (!isPlaying) {
                setTrigger1(!trigger1);
                fetch(
                  "https://coach-holiday-interective-middleware.onrender.com/firework",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },

                    body: JSON.stringify({
                      colour: 1,
                    }),
                  }
                )
                  .then((res) => res.text())
                  .then((data) => {})
                  .catch((err) => {
                    console.error(err);
                  });

                setIsPlaying(true);
              }
            }}
          >
            <SvgComponent1
              trigger1={trigger1}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
            />
          </div>

          <div
            className="imgContainer firework3"
            style={{
              height: "12svh",
              width: "12svh",
              margin: "0 0.33rem",
            }}
            onClick={() => {
              if (!isPlaying) {
                setTrigger1(!trigger1);
                fetch(
                  "https://coach-holiday-interective-middleware.onrender.com/firework",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },

                    body: JSON.stringify({
                      colour: 2,
                    }),
                  }
                )
                  .then((res) => res.text())
                  .then((data) => {})
                  .catch((err) => {
                    console.error(err);
                  });

                setIsPlaying(true);
              }
            }}
          >
            <SvgComponent2
              trigger1={trigger1}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
            />
          </div>
        </div>
      </div>

      <Button
        size="50%"
        param="PLAY HOLIDAY GAME"
        backgroundColor="#C22033"
        onClick={async () => {
          trackBtn("toCoachholidaygame");
          window.open("https://sg.coachholidayornamenthunt.com", "_blank");
        }}
      />

      <Button
        size="50%"
        param="RESTART"
        backgroundColor="#C22033"
        onClick={async () => {
          trackBtn("restart");
          window.location.reload();
        }}
      />
    </div>
  );
};
