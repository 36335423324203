import { useMemo, useState } from "react";
import { Input, Page12, Page3, Page4, Page6 } from "./pages";
const Overlay = ({
  phaserRef,
  canvasRef,
  currentPage,
  setCurrentPage,
  setInfo,
  info,
}) => {
  useMemo(() => {
    if (phaserRef.current) {
      if (currentPage === 5) {
        phaserRef.current.scene.game.scene.scenes[1].swipe.alpha = 1;
      } else {
        phaserRef.current.scene.game.scene.scenes[1].swipe.alpha = 0;
      }
    }
  }, [currentPage]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        width: "100vw",
        height: "100vh",
        zIndex: 100,
        pointerEvents: "none",
        maxWidth: "700px",
      }}
    >
      <Page12
        canvasRef={canvasRef}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        index={[1, 2]}
        info={info}
      />

      <Page3
        canvasRef={canvasRef}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        phaserRef={phaserRef}
        index={[3]}
        setInfo={setInfo}
        info={info}
      />
      <Page4
        canvasRef={canvasRef}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        phaserRef={phaserRef}
        index={[4]}
        setInfo={setInfo}
        info={info}
      />
      <Page6
        canvasRef={canvasRef}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        index={[6]}
        setInfo={setInfo}
        info={info}
      />
    </div>
  );
};

export default Overlay;
