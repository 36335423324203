import Phaser from "phaser";

export class Flake {
  constructor(my) {
    //make the graphic
    //
    //
    this.x = my.game.config.width * Math.random();
    this.y = my.game.config.width * Math.random();
    this.r = Math.random() * 10;
    this.circle = my.add.circle(this.x, this.y, this.r, 0xffffff);
    this.circle.setDepth(-10);
    this.circle.setDepth(2);
    this.drift = Math.random() * 2 - Math.random() * 2;
    this.fallSpeed = Math.random() * 2 + Math.random() * 0.5;
    this.circle.alpha = 0.1 + Math.random() * 0.9;
    this.my = my;
  }

  move() {
    this.circle.x += this.drift;
    this.circle.y += this.fallSpeed;

    if (this.circle.y > this.my.game.config.height) {
      this.circle.y = 0;
    }
  }
}
