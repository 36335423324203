import "./App.css";
import THREESCENE from "./3d";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSuperFan } from "./context";
import IoPhaser from "./2d";
import Overlay from "./overlay";
import { LegerLine } from "./overlay/components/index.js";
import { useProgress } from "@react-three/drei";
function App() {
  let canvasRef = useRef(null);
  let textureRef = useRef(null);
  const [info, setInfo] = useState({
    name: "",
    email: "",
    terms: false,
    color: null,
    sender: "",
    url: "",
  });

  const [_currentPage, setCurrentPage] = useState(1);
  const currentPage = useMemo(() => {
    return _currentPage;
  }, [_currentPage]);

  const [cameraFocus, setCameraFocus] = useState(false);

  const { uploadFile } = useSuperFan();

  useEffect(() => {
    // score to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <IoPhaser ref={canvasRef} currentPage={currentPage} />
      <THREESCENE
        canvasRef={textureRef}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setInfo={setInfo}
        info={info}
        cameraFocus={cameraFocus}
      />
      <Overlay
        canvasRef={textureRef}
        phaserRef={canvasRef}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setInfo={setInfo}
        info={info}
      />
      <TextureCanvas ref={textureRef} />

      <LegerLine />

      <Preload />
    </div>
  );
}

export const Preload = () => {
  const [isloaded, setLoaded] = useState(false);
  const { active, progress, errors, item, loaded, total } = useProgress();

  useEffect(() => {
    if (progress === 100) {
      setLoaded(true);
    }
  }, [progress]);
  return (
    <div
      className="preloaddd"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        opacity: isloaded ? 0 : 1,
        zIndex: 1000000,
        transition: "all 0.5s ease",
        pointerEvents: isloaded ? "none" : "all",
        width: "100vw",
        height: "100svh",
        backgroundColor: "#c41f32",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <div
          className="imgContainer"
          style={{
            height: "20svh",
          }}
        >
          <img src="/2d/loading.gif" alt="loadingg" />
        </div>
        {/* <SVGComponent start={currentPage} /> */}
      </div>
    </div>
  );
};

const TextureCanvas = React.forwardRef((props, ref) => {
  useEffect(() => {
    ref.current.width = 1024;
    ref.current.height = 512;
    let ctx = ref.current.getContext("2d");
    let img = new Image();
    img.src = "/2d/Glow_icon.webp";
    img.onload = () => {
      ctx.drawImage(
        img,
        ref.current.width / 2 - 500 * 0.3 * 0.5,
        ref.current.height * 0.25 - 252 * 0.3 * 0.5,
        500 * 0.3,
        252 * 0.3
      );
    };

    // wait until font is loaded
    document.fonts.ready.then(() => {
      ctx.font = "38px HelveticaLTPro-Bold";
      ctx.fillStyle = "#ffffff";
      ctx.textAlign = "center";

      ctx.fillText(
        "Type your".toUpperCase(),
        ref.current.width / 2,
        ref.current.height * 0.42
      );

      ctx.fillText(
        "message here".toUpperCase(),
        ref.current.width / 2,
        ref.current.height * 0.42 + 38
      );

      // ctx.fillText(
      //   "Happy New Year",
      //   ref.current.width / 2,
      //   ref.current.height * 0.42 + 38 * 2
      // );

      //
      ctx.font = "28px HelveticaLTPro-Bold";

      ctx.fillText(
        "Your Name".toUpperCase(),
        ref.current.width / 2,
        ref.current.height * 0.68
      );

      // ctx.fillText(
      //   "Who Who",
      //   ref.current.width / 2,
      //   ref.current.height * 0.68 + 30
      // );
    });
  }, []);
  return (
    <div
      style={{
        width: 10,
        height: 10,
        overflow: "hidden",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "-10",
      }}
    >
      <canvas
        ref={ref}
        style={{
          backgroundColor: "red",
          opacity: "0",
        }}
      ></canvas>
    </div>
  );
});

export default App;
